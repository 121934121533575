import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "deploy"
    }}><inlineCode parentName="h1">{`deploy`}</inlineCode></h1>
    <p>{`This command send files to a remote server. An Appache `}</p>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{}}>{`yarn install @redoute/deploy
`}</code></pre>
    <p>{`You can now add the command to the scripts of your package.json`}</p>
    <p>{`Example:`}</p>
    <pre><code parentName="pre" {...{}}>{`redoute-deploy \\
  --path /Users/leogourven/Sites/never-lost-again/build \\
  --remotePath /var/www/experiments --host 54.39.191.140 \\
  --username deployer \\
  --privateKey /Users/leogourven/.ssh/deployer-nla 
`}</code></pre>
    <p>{`Where the 3 parameters are :`}</p>
    <pre><code parentName="pre" {...{}}>{`--path: Local of the directory to upload
--remotePath:Remote directory to put the files
--host: Host the server
--username: The Username to use to connect to the server
--privateKey: path of the PrivateKey
--baseUrl: base url of the (example: nla.dataveyes.com, will deploy on experimentName.datavetes.com) (optional)
--name: Bame of the deploy (default: branch name)

`}</code></pre>
    <h2 {...{
      "id": "server-configuration"
    }}>{`Server configuration`}</h2>
    <p>{`You need to activate the Apache `}<inlineCode parentName="p">{`mod_vhost_alias`}</inlineCode>{` module and configure the server this way.`}</p>
    <pre><code parentName="pre" {...{}}>{`<VirtualHost *:80>
  ServerName nla.dataveyes.com
  ServerAlias *.nla.dataveyes.com
  VirtualDocumentRoot /var/www/experiments/%1
</VirtualHost>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      